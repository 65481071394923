import React from "react"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBar from "../components/hero-bar"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const ParentPage = () => {
  const data = useStaticQuery(graphql`
    query ParentPageQuery {
      formIcon: file(relativePath: { eq: "medical.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      injury: file(relativePath: { eq: "injury.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      allContentfulParentSignUpNotification {
        edges {
          node {
            link
            showOnWebsite
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Parents" />
      <HeroBar subtitle="Parent Information" />
      <div className="has-background-white-ter has-margin-top-15">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <div className="has-margin-top-15 has-margin-15-mobile">
                <div className="tile">
                  <h1 className="title has-padding-bottom-5 why-cav">
                    Parents
                  </h1>
                </div>
                <div className="tile">
                  <h3 className="subtitle">
                    Thank you for allowing us to help develop the basketball
                    skills of your young Cavalier!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.allContentfulParentSignUpNotification.edges[0].node
        .showOnWebsite && (
        <div className="columns is-mobile">
          <div className="column is-10-mobile is-offset-1-mobile is-6-desktop is-offset-3-desktop">
            <article className="message is-info has-margin-top-25">
              <div className="message-body is-size-5">
                <a
                  href={
                    data.allContentfulParentSignUpNotification.edges[0].node
                      .link
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIGN UP HERE
                </a>{" "}
                for your volunteer spot.
              </div>
            </article>
          </div>
        </div>
      )}
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <div className="column is-12 section-header">
              <h4 className="title has-margin-bottom-5">Volunteer</h4>
            </div>
            <div className="column is-12 has-margin-bottom-25">
              <p className="is-size-4">
                <em>We need your help!</em> As a condition for receiving use of
                the Bishop Neumann and St. Wenceslaus gyms for practice, Cavalry
                Basketball is responsible for covering the concession stand and
                gate at certain Neumann basketball games. In appreciation for
                the large number of hours volunteered by Cavalry coaches,
                families of Cavalry coaches are not asked to work a
                gate/concession shift. This means that{" "}
                <strong>
                  we need EVERY (non-coaching) family to supply one adult
                  volunteer for one shift.
                </strong>{" "}
                Truly, we will need every family to sign up if we are to cover
                the required shifts. By signing up early you will get the best
                choice among shifts, and also helps us avoid having to call and
                email you to find a shift.
              </p>
              <p className="is-size-4">
                <strong>***</strong> If you would prefer not to volunteer (or if
                you do not sign up to volunteer and we are unable to reach you
                about volunteering) Cavalry Basketball will be following the
                procedure used by other Cavalry sports clubs and will deem one
                $50 deposit "forfeited" in place of volunteering. The money from
                that deposit will be used to secure a paid volunteer to cover a
                needed shift on your behalf.
              </p>
              <p className="is-size-4">
                {" "}
                Thank you in advance for your tremendous help and support with
                our Cavalry Basketball program!
              </p>
            </div>
            <div className="columns is-mobile has-padding-top-25">
              <div className="column is-12 section-icon">
                <Img
                  fixed={data.injury.childImageSharp.fixed}
                  alt="Cavalry Basketball"
                />
              </div>
            </div>
            <div className="column is-12 section-header">
              <h4 className="title has-margin-bottom-5">Concussions</h4>
            </div>
            <div className="column is-12">
              <p className="is-size-4">
                Concussions in athletics are a very serious problem. They are
                also very easily treated, if detected early!! Cavalry Basketball
                has committed to 3 things regarding concussions — early
                detection, appropriate rest time following a concussion, and no
                return-to-play without a concussion clearing form (link below)
                from a physician.
              </p>
              <p className="is-size-4">
                Here are a few common indicators of a concussion:
              </p>
              <ul className="is-size-5">
                <li>Headache or “pressure” in head</li>
                <li>Nausea or vomiting</li>
                <li>
                  Balance problems or dizziness, or double or blurry vision
                </li>
                <li>Bothered by light or noise</li>
                <li>Feeling sluggish, hazy, foggy, or groggy</li>
                <li>Confusion, or concentration or memory problems</li>
                <li>Just not “feeling right,” or “feeling down”</li>
              </ul>
              <p className="is-size-4">
                If you think your child might have a concussion – play it safe.
                Stop play immediately and get them checked out by a trainer or
                physician.
              </p>
            </div>
            <div className="columns is-mobile">
              <div className="column is-12 section-icon">
                <Img
                  fixed={data.formIcon.childImageSharp.fixed}
                  alt="Cavalry Basketball"
                />
              </div>
            </div>
            <div className="column is-12 section-header">
              <h4 className="title has-margin-bottom-5">Forms</h4>
            </div>
            <div className="column is-12">
              <div className="has-margin-bottom-15">
                <a
                  className="forms-link"
                  href="/docs/nsaa-returntoplay.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Return-to-Play Clearance Form
                </a>
              </div>
              <div className="has-margin-bottom-15">
                <a
                  className="forms-link"
                  href="/docs/registration-and-release-1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Registration and Release Form
                </a>
              </div>
              <div className="has-margin-bottom-15">
                <a
                  className="forms-link"
                  href="/docs/concussion-signs.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Concussion and Brain Injury Information
                </a>
              </div>
              <div className="has-margin-bottom-15">
                <a
                  className="forms-link"
                  href="/docs/concussion-verification-sheet.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Verification Form - Concussion and Brain Injury Information
                </a>
              </div>

              <p className="is-size-4">
                You can also go to the{" "}
                <a
                  href="https://bishopneumann.com/athletics/catholic-youth-activities-organization/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CYAO
                </a>{" "}
                page to see all forms necessary for Cavalry Basketball members
                to uphold their Catholic identity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ParentPage
